import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Commands } from '@shared/enums/commands.enum';
import { TeamType } from '@shared/models/account.model';
import { CreateOptions, QuestionData, SurveyModel, TemplateData } from '@shared/models/survey.model';
import { AccountState } from '@shared/states/account.state';
import { LoadTemplates, SetTemplate } from '@shared/states/templates.actions';
import { from, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { assignTemplateLogicKeys } from '@shared/utilities/survey-data.utilities';
import { createDebug } from '@shared/states/survey.functions';
import { CloudFunctions } from './cloud-functions.service';
import { DatabaseWrapper } from './database-wrapper.service';
import { unwrapOrderDataFromSnapshot } from './objects-manager.service';
import { SnackbarService } from './snackbar.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class BlueprintsManager {
  constructor(
    private db: DatabaseWrapper,
    private store: Store,
    private ns: SnackbarService,
    private cf: CloudFunctions,
  ) {}

  loadCategories() {
    return this.db
      .list('/templates/categories', (ref) => ref.orderByPriority())
      .snapshotChanges()
      .pipe(
        map(unwrapOrderDataFromSnapshot),
        catchError(() => of([])),
      );
  }

  loadTemplates(teamKey: string): Observable<SurveyModel[]> {
    const templatesPath = teamKey;

    return this.db
      .list<SurveyModel>(`/blueprints/${templatesPath}`)
      .snapshotChanges()
      .pipe(
        map((snapshots) => {
          return snapshots
            .map((snapshot) => {
              const data = snapshot.payload.val();
              const template = { ...data.template, questionCount: data.questions?.length, teamKey };
              const admin = this.store.selectSnapshot(AccountState.adminSettings);

              if (
                admin.type === TeamType.Child &&
                !!template.draft &&
                template.teamKey !== environment.templatesTeamKey
              ) {
                return null;
              }

              const questions = data.questions
                ? data.questions.map(assignTemplateLogicKeys).map((question: QuestionData) => {
                    if (question?.choiceList) {
                      question.choiceList = question.choiceList.map((choice, idx) => ({ $key: idx, ...choice }));
                    }

                    return question;
                  })
                : [];
              const outcomes = data.outcomes ? data.outcomes.map(assignTemplateLogicKeys) : [];
              const triggers = data.triggers ? data.triggers.map(assignTemplateLogicKeys) : [];

              const model = { $key: snapshot.key, ...data, template, questions, outcomes, triggers };
              return model as any as SurveyModel;
            })
            .filter(Boolean);
        }),
        catchError(() => of([])),
      );
  }

  toggleTemplate(teamKey: string, templateKey: string, value: boolean) {
    return from(this.db.object(`/blueprints/${teamKey}/${templateKey}/template/draft`).set(value));
  }

  createTemplate(config: Partial<CreateOptions>) {
    config.userKey = 'userKey' in config ? config.userKey : this.store.selectSnapshot(AccountState.userKey);
    config.teamKey = 'teamKey' in config ? config.teamKey : this.store.selectSnapshot(AccountState.teamKey);

    return this.cf.postOnce(Commands.CreateTemplate, undefined, config).pipe(
      tap((response) => {
        if (Object.keys(response || {})?.length > 1) {
          // dryrun === true; response is JSON object
          return createDebug(config, response);
        }

        const { admin, command, teamKey } = config;

        const GoToTemplate = new Navigate([`surveys/create/${response?.templateKey || ''}`]);
        const GoToEditor = new Navigate([`surveys/edit/${response?.surveyKey}/build`]);
        const createdNotification: any = {
          color: 'success',
          actionName: $localize`Open`,
          actionCallback: () => this.store.dispatch(GoToTemplate),
        };

        this.store.dispatch(new LoadTemplates(teamKey));

        switch (command) {
          case 'import_draft':
            return admin ? this.ns.open($localize`Template created`, createdNotification) : null;

          case 'import_blueprint':
            return admin ? this.ns.open($localize`Template duplicated`, createdNotification) : null;

          case 'update_blueprint':
            return null;

          case 'edit_blueprint':
            return this.store.dispatch(GoToEditor);

          case 'delete_blueprints':
            return this.store.dispatch([GoToTemplate, new SetTemplate()]);
        }
      }),
      catchError(() => of(null)),
    );
  }

  updateTemplateData(teamKey: string, data: TemplateData) {
    const templateKey = data.templateKey;
    return from(this.db.object(`/blueprints/${teamKey}/${templateKey}/template`).set(data));
  }
}
