import { OpenLanguageSelectOptions } from '@editor/shared/states/language/language.models';

import { LanguageData2, TranslatorEntry } from '@shared/models/locale.model';
import { AutoTranslateRequest } from '@editor/shared/sidenavs/language-editor/language-editor.models';
import { KeyData } from '@shared/models/order.model';
import { InfoMediaCtaData, QuestionData } from '@shared/models/survey.model';
import { Questions } from '@shared/enums/questions.enum';

export class LanguageReset {
  static type = '[Language] Reset';
}

export class LanguageDetected {
  static type = '[Language] Detected';

  constructor(readonly language: string) {}
}

export class SetSelectedLanguage {
  static type = '[Language] Set Select';

  constructor(readonly language: string | null) {}
}

export class OpenLanguageSelect {
  static type = '[Language] Open Select';

  constructor(readonly options: OpenLanguageSelectOptions) {}
}

export class AddLanguage {
  static type = '[Language] Add';

  constructor(
    readonly language: string,
    readonly skipCheck?: boolean,
  ) {}
}

export class DeleteLanguage {
  static type = '[Language] Delete';

  constructor(readonly language: string) {}
}

export class UpdateLanguageData {
  static type = '[Language] Update data';

  constructor(
    readonly language: string,
    readonly data: Partial<LanguageData2>,
  ) {}
}

export class UpdateLanguageStrings {
  static type = '[Language] Update strings';

  constructor(
    readonly items: TranslatorEntry[] | TranslatorEntry,
    readonly language?: string,
    readonly languageUpdate?: Partial<LanguageData2>,
    readonly useText?: boolean,
    readonly replaceExiting?: string,
  ) {}
}

export class DetectLanguageTrigger {
  static type = '[Language] Detect language trigger';
}

export class CopyLanguageTypeLabels {
  static type = '[Language] Copy Type Labels';

  constructor(
    readonly source: QuestionData,
    readonly target: string,
    readonly type: Questions,
  ) {}
}

export class UpdateCtaLanguageStrings {
  static type = '[Language] Update Cta strings';

  constructor(
    readonly key: string,
    readonly update: Partial<InfoMediaCtaData>,
  ) {}
}

export class SetDefaultLanguage {
  static type = '[Language] Set Default';

  constructor(
    readonly language: string,
    readonly replace?: boolean,
  ) {}
}

export class AddHiddenLanguages {
  static type = '[Language] Add Hidden';
}

export class RemoveHiddenLanguages {
  static type = '[Language] Remove Hidden';
}

export class ExportXliff {
  static type = '[Language] Export Xliff';

  constructor(readonly language: string) {}
}

export class ImportXliff {
  static type = '[Language] Import Xliff';

  constructor(
    readonly language: string,
    readonly file: File,
  ) {}

  toJSON = () => ({ ...this, type: ImportXliff.type, file: this.file.name });
}

export class AutoTranslate {
  static type = '[Language] Auto Translate';

  constructor(
    readonly request: AutoTranslateRequest,
    readonly languageUpdate?: Partial<LanguageData2>,
  ) {}
}

export class GenerateItemLocals<T extends KeyData> {
  static type = '[Language] Generate Item Locals';

  constructor(
    readonly items: T[],
    readonly type: 'questions' | 'outcomes',
    readonly language?: string,
  ) {}
}
