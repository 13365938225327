import { ZefThemePalette } from '@shared/models/color.model';

export enum Status {
  Warning = 'warning',
  Offline = 'offline',
  Online = 'online',
  Error = 'error',
  Unknown = 'unknown',
}

export type StatusPathReplaceKey = 'teamKey' | 'userKey' | 'surveyKey' | 'emailKey' | 'smsKey';

export interface StatusData {
  time: string;
  valid: string;

  period: string;

  active: boolean;

  count: number;

  failed: number;
  success: number;
  retried: number;

  processed: number;
  sessions?: number;
}

export enum StatusPath {
  TestEmail = `test/{teamKey}/email`,
  TestSms = `test/{teamKey}/sms`,
}

export const StatusColorMap: Record<Status, ZefThemePalette> = {
  [Status.Offline]: 'alert',
  [Status.Warning]: 'warning',
  [Status.Online]: 'success',
  [Status.Error]: 'alert',
  [Status.Unknown]: 'stealth',
};
