<ng-container *ngIf="!!templates.length && (activeTemplateKey | async) as templateKey; else spinner">
  <div
    *ngIf="{
      model: surveyModel(templateKey),
      next: nextTemplate(),
      prev: prevTemplate(),
      content: (activeContentView | async),
      mobile: (isMobile$ | async),
    } as asyncData"
    class="zef-no-select container-box z-fx z-fx-fill z-fx-h100"
  >
    <button
      *ngIf="showNavigation && !asyncData.mobile"
      class="z-dialog-prev"
      [disabled]="!asyncData.prev"
      (click)="gotoPrev()"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>

    <div class="z-fx-col z-fx-flex">
      <mat-toolbar *ngIf="!asyncData.mobile" class="z-fx-none z-fx-sc z-fx-gap-8">
        <zef-select [value]="templateKey" (change)="changeTemplate($event)">
          <zef-select-option *ngFor="let model of templates" [value]="model.template.templateKey">
            {{ model.template?.name }}
          </zef-select-option>
        </zef-select>

        <div class="zef-text-h3" *ngIf="!showNavigation">{{ asyncData.template?.name }}</div>

        <div class="z-fx z-fx-end-center z-fx-gap-8 z-fx-flex">
          <a
            class="toolbar-tab"
            [class.active]="asyncData.content === 'intro'"
            i18n
            (click)="changeContentView('intro')"
          >
            Preview
          </a>
          <mat-icon class="z-button-disabled">editor_question_dropdown</mat-icon>
          <a
            class="toolbar-tab"
            [class.active]="asyncData.content === 'report'"
            i18n
            (click)="changeContentView('report')"
          >
            View report
          </a>
          <mat-icon class="z-button-disabled">editor_question_dropdown</mat-icon>
        </div>

        <div class="z-fx z-fx-end-center z-fx-gap-4 z-fx-none">
          <button mat-raised-button color="primary" i18n (click)="selectTemplate(templateKey)">
            Select this survey
          </button>

          <button *ngIf="showNavigation" color="secondary" mat-icon-button (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-toolbar>

      <zef-template-preview
        class="z-fx-flex"
        [model]="asyncData.model"
        [isMobile]="asyncData.mobile"
        [viewMode]="asyncData.content"
        (select)="selectTemplate(templateKey)"
      ></zef-template-preview>
    </div>

    <button
      *ngIf="showNavigation && !asyncData.mobile"
      class="z-dialog-next"
      [disabled]="!asyncData.next"
      (click)="gotoNext()"
    >
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</ng-container>

<ng-template #spinner>
  <zef-spinner size="huge"></zef-spinner>
</ng-template>
