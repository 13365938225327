export interface PollRouteData {
  hideFooter?: boolean;
  hideHeader?: boolean;
}

export interface PollIdData {
  key: string;
  displayName: string;
  image: string;
  notes: string;
  closingFrame: 'endOfTheDay' | 'endOfTheNextDay' | 'endOfTheWeekSunday' | 'endOfTheMonth';
  surveyUrl: string;
  teamKey: string;
  surveyKey: string;
  linkKey: string;
  locales?: string[];
}

export interface PollSessionData {
  shareKey: string;
  sessionKey: string;
  closingTime: number;
  qr: string;
  sessionNr: number;
}

export const enum PollResource {
  Session = 'poll-session',
  Data = 'poll',
  Languages = 'languages',
  Language = 'poll_{lang}',
}

export type PollLocales = Record<string, PollLocale>;

export interface PollLocale {
  name: string;
  code: string;
  default?: boolean;
}

export const defaultLanguages: PollLocales = {
  en: { name: 'English', code: 'en' },
  fi: { name: 'Suomi', code: 'fi' },
  sv: { name: 'Svenska', code: 'sv' },
};

export const enum PollCloudFunction {
  Poll = 'services-poll',
}

export const enum PollCloudMethod {
  CreateSession = 'create-session',
}

export const PollPrefix = 'poll__';

export function getPollLocales(locale: string): Record<keyof typeof PollLocaleKey, string> | undefined {
  return (
    PollLocales[locale] &&
    Object.entries(PollLocales[locale]).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [`${PollPrefix}${k}`]: v,
      }),
      {} as Record<keyof typeof PollLocaleKey, string>,
    )
  );
}

export enum PollLocaleKey {
  CreateSurvey = 'Create a session',
  ClosingTime = 'Closing time:',
  ViewResults = 'View results',
  ScanMe = 'Scan me',
  EnterCodeAt = 'Enter the code at',
  EnterPollCode = 'Enter Live Poll code',
  StartSurvey = 'Start survey',
  AnswerAgain = 'Answer again',
  Go = 'Go',
  InvalidCode = 'Invalid code',
  SessionNr = 'Session {0}',
  SessionExpiring = 'Expiring in {0}',
  Expired = 'Expired',
  Second = 'second',
  Seconds = 'seconds',
  Minute = 'minute',
  Minutes = 'minutes',
  Hour = 'hour',
  Hours = 'hours',
  Day = 'day',
  Days = 'days',
}

const PollLocales: Record<string, Record<keyof typeof PollLocaleKey, string>> = {
  en: PollLocaleKey,
  fi: {
    CreateSurvey: 'Luo istunto',
    ClosingTime: 'Sulkeutuu:',
    ViewResults: 'Katso tulokset',
    ScanMe: 'Skannaa minut',
    EnterCodeAt: 'Syötä koodi osoitteessa',
    EnterPollCode: 'Syötä Live Poll -koodi',
    StartSurvey: 'Vastaa kyselyyn',
    AnswerAgain: 'Vastaa uudelleen',
    Go: 'Siirry',
    InvalidCode: 'Virheellinen koodi',
    SessionNr: 'Istunto {0}',
    SessionExpiring: 'Vanhenee {0} kuluttua',
    Expired: 'Vanhentunut',
    Second: 'sekunnin',
    Seconds: 'sekunnin',
    Minute: 'minuutin',
    Minutes: 'minuutin',
    Hour: 'tunnin',
    Hours: 'tunnin',
    Day: 'päivän',
    Days: 'päivän',
  },
  sv: {
    CreateSurvey: 'Skapa en session',
    ClosingTime: 'Stänger:',
    ViewResults: 'Se resultaten',
    ScanMe: 'Skanna mig',
    EnterCodeAt: 'Ange koden i adressen',
    EnterPollCode: 'Ange Live Poll-koden',
    StartSurvey: 'Svara på enkäten',
    AnswerAgain: 'Svara igen',
    Go: 'Flytta dig',
    InvalidCode: 'Ogiltig kod',
    SessionNr: 'Session {0}',
    SessionExpiring: 'Upphör om {0}',
    Expired: 'Föråldrad',
    Second: 'av en sekund',
    Seconds: 'sekunder',
    Minute: 'en minut',
    Minutes: 'minuter',
    Hour: 'timme',
    Hours: 'timmar',
    Day: 'dag',
    Days: 'dagar',
  },
};
