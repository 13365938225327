/**
 * Manages scoring data stored in the Firebase.
 *
 * @unstable
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Answers } from '@shared/enums/answers.enum';
import { ScoringData, SurveyScoring } from '@shared/models/survey.model';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { AccountState } from '@shared/states/account.state';
import { take, filter, switchMap } from 'rxjs/operators';
import { KeyData } from '@shared/models/order.model';
import { Observable } from 'rxjs';

/**
 * Manages scoring conditions for the survey outcomes.
 */
@Injectable({
  providedIn: 'root',
})
export class ScoringManager {
  constructor(
    private store: Store,
    private db: DatabaseWrapper,
  ) {}

  createScoringData(
    scoring: SurveyScoring | ScoringData[],
    outcomes: KeyData[],
    questions: {
      $key: string;
      choiceList: {
        $key: any;
      }[];
    }[],
  ) {
    return !Array.isArray(scoring)
      ? // duplicate survey
        scoring
      : // new survey from template
        outcomes.reduce((dict1, { $key: outcomeKey }, outcomeIndex) => {
          const outcomeScoring = questions.reduce((dict2, { $key: questionKey, choiceList }, questionIndex) => {
            const questionScoring = scoring[outcomeIndex][questionIndex] || null;

            if (choiceList?.length && questionScoring && questionScoring['value']) {
              questionScoring['value'] = questionScoring['value']
                .split(';')
                .map((val) => choiceList[val]?.$key)
                .join(';');
            }

            return { ...dict2, [questionKey]: questionScoring };
          }, {});

          return { ...dict1, [outcomeKey]: outcomeScoring };
        }, {});
  }

  public updateScoring(surveyKey: string, data: SurveyScoring) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const fbOject = this.db.object<any>(`/scoring/${teamKey}/${surveyKey}`);
    fbOject.update(data);
  }

  /**
   * Clears answer but doesn't delete the whole value.
   *
   * @param scoringObs  [description]
   * @param to          [description]
   */
  public clearScoring(to: string, surveyKey: string | null) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    const fbOject = this.db.object<any>(`/scoring/${teamKey}/${surveyKey}`);

    if (fbOject && to) {
      const obj = {};

      const scoringData = {
        type: Answers.NONE,
        value: null,
      };

      obj[to] = scoringData;

      fbOject.update(obj);
    }
  }

  setScoring(teamKey: string, surveyKey: string, scoring: SurveyScoring) {
    return this.db.object(`/scoring/${teamKey}/${surveyKey}`).set(scoring);
  }

  copyOutcomeScoring(from: string, to: string, surveyKey: string | null): Observable<unknown> {
    const scoring = this.db.object<SurveyScoring>(
      `/scoring/${this.store.selectSnapshot(AccountState.teamKey)}/${surveyKey}`,
    );

    return scoring.valueChanges().pipe(
      take(1),
      filter((scores) => scores?.hasOwnProperty(from)),
      switchMap((scores) => scoring.update({ [to]: scores[from] })),
    );
  }
}
