/**
 * Answer value types.
 *
 * @unstable
 */

export enum Answers {
  NONE = 'none',
  DATE = 'date',
  EMAIL = 'email',
  PHONE = 'phone',
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',

  ARRAY_NUMBER = 'array-number',
  ARRAY_STRING = 'array-string',
}
