import { StateToken } from '@ngxs/store';

import { LanguageData2 } from '@shared/models/locale.model';

export const LanguageOtherToken = 'other';

export const LanguageOtherName = 'Other';

export interface LanguageStateModel {
  selectedLanguage?: string;
}

export interface ContinueAction {
  type: string;
  new (language: string, ...args: any[]): any;
}

export interface OpenLanguageSelectOptions {
  hideOther?: boolean;
  selected?: string;
  disabled?: string[];
  continueAction: ContinueAction | ContinueAction[];
  continueParams?: any;
  languages?: LanguageData2[];
}

export const LANGUAGE_STATE_TOKEN = new StateToken<LanguageStateModel>('language');
