import { filter, map } from 'rxjs/operators';
import { combineLatest, Observable, of, switchMap } from 'rxjs';

import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { shareRef } from '@shared/operators/share-ref.operator';
import { SurveyIndex } from '@shared/states/index/index-state.models';
import { AccountState } from '@shared/states/account.state';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class IndexApiService {
  constructor(
    private db: DatabaseWrapper,
    private store: Store,
  ) {}

  getSurveysIndex(): Observable<SurveyIndex[]> {
    return combineLatest([
      this.store.select(AccountState.teamKey),
      this.store.select(AccountState.userSurveys),
      this.store.select(AccountState.isTeamAdmin),
    ]).pipe(
      filter(([team]) => !!team),
      switchMap(([team, userSurveys, teamAdmin]) => {
        if (teamAdmin) {
          return this.getAllSurveys(team);
        } else if (userSurveys.length) {
          return this.getUserSurveys(team, userSurveys);
        } else {
          return of([]);
        }
      }),
      map((surveys: SurveyIndex[]) => surveys.filter((survey) => !!survey.surveyName)),
      shareRef(),
    );
  }

  private getAllSurveys(team: string): Observable<SurveyIndex[]> {
    return this.db
      .list<SurveyIndex>(`/index/${team}/surveys`)
      .snapshotChanges()
      .pipe(
        map((surveys) =>
          surveys.map((survey) => ({
            ...survey.payload.val(),
            $key: survey.payload.key,
          })),
        ),
      );
  }

  private getUserSurveys(team: string, userSurveys: string[]): Observable<SurveyIndex[]> {
    return combineLatest(
      userSurveys.map((survey) =>
        this.db
          .object<SurveyIndex>(`/index/${team}/surveys/${survey}`)
          .valueChanges()
          .pipe(
            map((surveyData) => ({
              ...surveyData,
              $key: survey,
            })),
          ),
      ),
    );
  }
}
