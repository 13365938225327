import { StateToken } from '@ngxs/store';
import { Status } from '@shared/models/status.model';
import { Rights } from '@shared/enums/rights.enum';

export interface SurveyIndex {
  $key: string;
  surveyName: string;
  background: string;
  respondents: number;
  owner: string;
  status: Status;
  users: { [uid: string]: Rights };
  modified: number;
  published: number;
  created: number;
}

export interface IndexStateModel {
  surveys: SurveyIndex[];
}

export const INDEX_STATE_TOKEN = new StateToken<IndexStateModel>('index');
