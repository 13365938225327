import { BuilderData, QuestionData } from '@shared/models/survey.model';

export enum ViewType {
  Question = 'question',
  Result = 'result',
  Trigger = 'triggers',
}

export interface LogicNavState {
  questionLogic?: boolean;
  preventScroll?: boolean;
  questionConfig?: boolean;
  questionIntegration?: boolean;
  questionAdded?: string;
}

export enum EditorViewSize {
  Large = 'large',
  Small = 'small',
  Illegal = 'illegal',
}

export class ChangeLogic {
  static readonly type = '[Editor] Change Logic';

  constructor(
    readonly logic: boolean,
    readonly object?: string,
    readonly state?: LogicNavState,
  ) {}
}

export class ChangeView {
  static readonly type = '[Editor] Change View';

  constructor(
    readonly type: ViewType,
    readonly object?: string,
  ) {}
}

export class ChangeObject {
  static readonly type = '[Editor] Change Object';

  constructor(
    readonly object: string,
    readonly edit?: ViewType,
    readonly logic?: boolean,
    readonly state?: LogicNavState,
  ) {}
}

export class ScrollToObject {
  static readonly type = '[Editor] Scroll to object';

  constructor(readonly object: string) {}
}

export class ScrollToElement {
  static readonly type = '[Editor] Scroll to Element';

  constructor(
    readonly object: string,
    readonly toBottom = false,
  ) {}
}

export class FocusActiveTitle {
  static readonly type = '[Editor] Focus active title';

  constructor(readonly object: string) {}
}

export class HighlightObject {
  static readonly type = '[Editor] Highlight Object';

  constructor(readonly object: string) {}
}

/**
 * `index = null` - closes drawer
 * `index = <number>` - open drawer to add new question at index position
 * `index = <string>` - open drawer to change type of question with index key
 */
export class AddItem {
  static readonly type = '[Editor] Add Item';

  constructor(
    readonly index: number | string | null,
    readonly group: string = '',
    readonly view: ViewType | null = null,
    readonly data?: Partial<BuilderData>,
  ) {}
}

export class ShowArchiveWarning {
  static readonly type = '[Editor] Show Archive Warning';
  constructor(readonly question: QuestionData) {}
}

export class ToggleArchivedQuestions {
  static readonly type = '[Editor] Toggle Archived Questions';
  constructor(readonly show: boolean) {}
}

export class ToggleArchivedOutcomes {
  static readonly type = '[Editor] Toggle Archived Outcomes';
  constructor(readonly show: boolean) {}
}

export class ToggleArchivedTriggers {
  static readonly type = '[Editor] Toggle Archived Triggers';
  constructor(readonly show: boolean) {}
}

export class GetRespondents {
  static readonly type = '[Editor] Get Respondents';

  constructor(readonly surveyKey: string | null) {}
}

export class SetScrolling {
  static readonly type = '[Editor] Set Scrolling';

  constructor(readonly scrolling: boolean) {}
}

export class PasteChoices {
  static readonly type = '[Editor] Paste Choices';
  constructor(readonly pastedText: string) {}
}

export class ExpandChoices {
  static readonly type = '[Editor] Expand Choices';
  constructor(readonly questionKey: string) {}
}
