import { Questions } from '@shared/enums/questions.enum';
import {
  CreateOptions,
  OutcomeData,
  QuestionData,
  SettingType,
  SurveyModel,
  SurveySetting,
} from '@shared/models/survey.model';
import { downloadObjectAsJson } from '@shared/utilities/dom.utilities';
import { hasItemArchivedDependencies, isItemArchived } from '@shared/utilities/survey-data.utilities';

export function getOutcomeScoringProgress(survey: SurveyModel, outcome: OutcomeData): [number, number] {
  if (!survey || !outcome) {
    return [0, 0];
  }

  const questionKeys = survey.questions
    .filter(
      (question) =>
        Questions.scorable(question) &&
        !isItemArchived(question, survey.questions) &&
        !hasItemArchivedDependencies(question, survey.questions),
    )
    .map(({ $key }) => $key);

  const scoredKeys = Object.keys((survey.scoring || {})[outcome.$key] || {}).filter((key) =>
    questionKeys.includes(key),
  );

  return [scoredKeys.length, questionKeys.length];
}

export function getScoringProgress(survey: SurveyModel, outcome: OutcomeData): number {
  const [scored, scorable] = getOutcomeScoringProgress(survey, outcome);

  return scored / scorable || 0;
}

export function canDeleteQuestion(uuid: string, settings: SurveySetting[] = []) {
  return !settings.some((s) => s.value === uuid && s.type === SettingType.QuestionDeletingDisabled);
}

export function canEditQuestion(uuid: string, settings: SurveySetting[] = []) {
  return !settings.some((s) => s.value === uuid && s.type === SettingType.QuestionEditingDisabled);
}

export function canEditAnonymity(settings: SurveySetting[] = []) {
  return !settings.some((s) => s.type === SettingType.SurveyAnonymityDisabled);
}

export function canEditLogic(settings: SurveySetting[] = []) {
  return !settings.some((s) => s.type === SettingType.SurveyLogicDisabled);
}

export function canEditSetup(settings: SurveySetting[] = []) {
  return !settings.some((s) => s.type === SettingType.SurveySetupDisabled);
}

export function canEditWelcome(settings: SurveySetting[] = []) {
  return !settings.some((s) => s.type === SettingType.SurveyWelcomeDisabled);
}

export function canEditSocial(settings: SurveySetting[] = []) {
  return !settings.some((s) => s.type === SettingType.SurveySocialDisabled);
}

export function canCreateInvites(settings: SurveySetting[] = []) {
  return !settings.some((s) => s.type === SettingType.SurveyShareInviteDisabled);
}

export function canCreateTriggers(settings: SurveySetting[] = []) {
  return !settings.some((s) => s.type === SettingType.SurveyNotificationDisabled);
}

export function canEditJson(settings: SurveySetting[] = []) {
  return !settings.filter(
    (setting) => ![SettingType.QuestionDeletingDisabled, SettingType.QuestionEditingDisabled].includes(setting.type),
  ).length;
}

export function canAddQuestionsCount(questions: QuestionData[], settings: SurveySetting[]): number {
  const limit = settings.find((s) => s.type === SettingType.QuestionLimitEnabled);
  const count = questions.filter((q) => !Questions.group(q) && !q.uuid)?.length || 0;

  return limit ? Math.max(0, limit.value - count) : Number.POSITIVE_INFINITY;
}

export function canAddQuestion(questions: QuestionData[], settings: SurveySetting[] = []) {
  return canAddQuestionsCount(questions, settings) > 0;
}

export function createDebug(
  { json, surveyKey, templateKey, teamKey, queryParams, dryrun, command }: Partial<CreateOptions>,
  model: SurveyModel,
) {
  if (json) {
    let filename = surveyKey || !templateKey ? 'survey' : 'template';
    if (queryParams?.includes('show')) {
      const fragments = queryParams.split('&').filter((f) => f.startsWith('show='));
      filename += '-(' + fragments[0]?.replace('show=', '').split(',').join(';') + ')';
    }

    filename += `-[${teamKey}]`;
    filename += surveyKey || templateKey ? `-[${surveyKey || templateKey}]` : '';

    downloadObjectAsJson(model, filename);
  } else if (dryrun) {
    console.warn(`Dry run: <${command}>`, model);
  }
}
