import { ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { CreateOptions, SurveyModel, TemplateData } from '@shared/models/survey.model';

export class SetTemplate {
  static readonly type = '[Templates] - Set Template';

  constructor(readonly templateKey?: string) {}
}

// @todo test if dialog still opens after removing the componentFactoryResolver/viewContainerRef when
// https://github.com/angular/components/issues/19616 or https://github.com/angular/components/issues/19335 are fixed
export class DiscoverTemplate {
  static readonly type = '[Templates] - Discover Template';

  constructor(
    readonly templateKey: string,
    readonly templates: SurveyModel[],
    readonly startView: 'intro' | 'report' = 'intro',
    readonly componentFactoryResolver?: ComponentFactoryResolver,
    readonly viewContainerRef?: ViewContainerRef,
  ) {}

  toJSON = () => ({
    ...this,
    type: DiscoverTemplate.type,
    templates: [],
    componentFactoryResolver: null,
    viewContainerRef: null,
  });
}

export class LoadTemplates {
  static readonly type = '[Templates] - Load Templates';
  constructor(readonly teamKey: string) {}
}

export class LoadCategories {
  static readonly type = '[Templates] - Load Categories';
}

export class FilterTemplates {
  static readonly type = '[Templates] - Filter Template';

  constructor(
    readonly filter: {
      query?: string;
      category?: string;
      language?: string;
      complexity?: number;
      visible?: boolean;
    },
  ) {}
}

export class ResetTemplatesFilter {
  static readonly type = '[Templates] - Reset Filter';
}

export class CreateTemplate {
  static readonly type = '[Templates] Create Template';

  constructor(readonly options: Partial<CreateOptions>) {}
}

export class UpdateTemplate {
  static readonly type = '[Templates] Update Template';

  constructor(readonly options: Partial<CreateOptions>) {}
}

export class UpdateTemplateData {
  static readonly type = '[Templates] Update Template Data';

  constructor(
    readonly teamKey: string,
    readonly data: TemplateData,
  ) {}
}

export class ToggleTemplate {
  static readonly type = '[Templates] Toggle Template';

  constructor(
    readonly teamKey: string,
    readonly templateKey: string,
    readonly value: boolean,
  ) {}
}
