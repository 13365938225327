/**
 * Manages translation data stored in the Firebase.
 *
 * @unstable
 */

// TODO: This should be simplified a bit and cleaned up

// TODO: Simplify the data structure creation
// TODO: Remove fallback keys when all surveys use $key

import { BehaviorSubject, forkJoin, from, Observable, of, switchMap } from 'rxjs';

import { catchError, map, shareReplay, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { AccountState } from '@shared/states/account.state';

import { OutcomeData, QuestionData, SharingData, SurveyData } from '@shared/models/survey.model';
import {
  LanguageData,
  LanguageData2,
  LocalesData,
  TranslationData,
  TranslationsData,
} from '@shared/models/locale.model';

import { TranslateApi } from '@shared/services/translate-api.service';

import { DatabaseWrapper } from '@shared/services/database-wrapper.service';

import { mapObjectKey } from '@shared/operators/map-object-key.operator';
import { LanguageOtherName, LanguageOtherToken } from '@editor/shared/states/language/language.models';
import {
  buildOutcomeLocales,
  buildOutcomeSharingLocales,
  buildQuestionLocales,
  buildSurveyLocales,
  buildSurveySharingLocales,
  translatorEntriesToUpdate,
} from '@shared/utilities/language.utilities';
import { KeyData, OrderData } from '@shared/models/order.model';
import { isEmpty } from '@shared/utilities/object.utilities';

@Injectable({
  providedIn: 'root',
})
export class LocalesManager {
  public surveyKey = new BehaviorSubject<string | null>(null);

  constructor(
    readonly db: DatabaseWrapper,
    readonly store: Store,
    readonly translate: TranslateApi,
  ) {}

  isoLocales(): Observable<LanguageData2[]> {
    return this.db
      .list(`/templates/iso-639-1`)
      .snapshotChanges()
      .pipe(
        map((list: any[]) => list.map(({ key, payload }) => ({ code: key, ...payload.val() }))),
        map((locales) => locales.filter((lang) => lang.rtl == null && !lang.noFlag)),
        catchError(() => of([])),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public removeLanguage(surveyKey: string, languageKey: string) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return from(this.db.object(`/locales/${teamKey}/${surveyKey}/config/${languageKey}`).remove());
  }

  public updateLanguage(surveyKey: string, languageKey: string, data: any) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    this.db.object(`/locales/${teamKey}/${surveyKey}/config/${languageKey}`).update(data);
  }

  public addSurveyLanguage(surveyKey: string, lcid: string, hidden?: boolean): Observable<string> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const code = lcid === LanguageOtherToken ? this.db.createPushId() : lcid;
    const name = lcid === LanguageOtherToken ? LanguageOtherName : null;

    return from(
      this.db.object<LanguageData2>(`locales/${teamKey}/${surveyKey}/config/${code}`).update({
        code,
        hidden: !!hidden,
        ...(name ? { name } : {}),
      }),
    ).pipe(map(() => code));
  }

  public updateSurveyLanguageData(surveyKey: string, lcid: string, data: Partial<LanguageData2>): Observable<unknown> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return from(
      this.db.object<LanguageData2>(`locales/${teamKey}/${surveyKey}/config/${lcid}`).update({
        ...data,
        code: lcid,
      }),
    );
  }

  public duplicateTranslations(
    locales: LocalesData,
    outcomes: OutcomeData[],
    questions: { $key: string; choiceList: KeyData[] }[],
    isTemplate: boolean,
  ) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const surveyKey = this.surveyKey.getValue();

    if (!isTemplate) {
      return this.db.object(`/locales/${teamKey}/${surveyKey}`).set(locales);
    } else {
      let newLocales = JSON.stringify(locales);

      const parseLocal = (type: 'question' | 'outcome', indices: number[], items: KeyData[]): void => {
        newLocales = newLocales.replace(
          new RegExp(`-${type}-${indices.join('-')}"`, 'g'),
          `-${items.map((i) => i.$key).join('-')}"`,
        );
      };

      outcomes.forEach((outcome, idx) => parseLocal('outcome', [idx], [outcome]));

      questions.forEach((question, idx) => {
        parseLocal('question', [idx], [question]);

        question.choiceList?.forEach((choice, choiceIdx) =>
          parseLocal('question', [idx, choiceIdx], [question, choice]),
        );
      });

      return this.db.object(`/locales/${teamKey}/${surveyKey}`).set(JSON.parse(newLocales));
    }
  }

  duplicateItemTranslations(
    surveyKey: string,
    items: (OrderData & { title: string })[],
    toKeys: string[],
    choiceKeys: Record<string, [string, string][]> = {},
  ): Observable<unknown> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const basePath = `locales/${teamKey}/${surveyKey}/strings`;

    return from(this.db.object<TranslationsData>(basePath).valueChanges()).pipe(
      take(1),
      switchMap((strings) => {
        const updates = Object.entries(strings || {}).reduce((comb, [lang, translations]) => {
          Object.entries(translations).forEach(([key, value]) => {
            items.forEach((item, i) => {
              if (key.includes(item.$key)) {
                if (!comb[lang]) {
                  comb[lang] = {};
                }

                let newKey = key.replace(item.$key, toKeys[i]);

                choiceKeys[toKeys[i]]?.forEach((choice) => {
                  newKey = newKey.replace(choice[0], choice[1]);
                });

                comb[lang][newKey] = value;
              }
            });
          });

          return comb;
        }, {});

        if (isEmpty(updates)) {
          return of(void 0);
        }

        return forkJoin(
          Object.entries(updates).map(([lang, update]) => this.db.object(`${basePath}/${lang}`).update(update)),
        );
      }),
    );
  }

  getAccreditedTranslations(): Observable<TranslationsData> {
    return this.db.object<TranslationsData>('templates/translations').valueChanges();
  }

  getAutomaticTranslations(): Observable<TranslationsData> {
    return this.db.object<TranslationsData>('translations/ui').valueChanges();
  }

  loadSurveyLocales(surveyKey: string, teamKey?: string): Observable<LocalesData> {
    teamKey = teamKey || this.store.selectSnapshot(AccountState.teamKey);

    return this.db.object<LocalesData>(`locales/${teamKey}/${surveyKey}`).valueChanges();
  }

  replaceSurveyLanguage(surveyKey: string, fromLanguage: string, toLanguage: string): Observable<unknown> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    toLanguage = toLanguage === LanguageOtherToken ? this.db.createPushId() : toLanguage;

    return forkJoin(
      ['config', 'strings'].map((path) => {
        const ref = this.db.object(`locales/${teamKey}/${surveyKey}/${path}/${fromLanguage}`);

        return ref.valueChanges().pipe(
          take(1),
          switchMap((fromConfig) =>
            forkJoin([
              this.db.object(`locales/${teamKey}/${surveyKey}/${path}/${toLanguage}`).set(fromConfig),
              ref.remove(),
            ]),
          ),
        );
      }),
    );
  }

  createLanguageLocal(
    surveyKey: string,
    language: LanguageData2,
    existingTranslations: TranslationData,
    surveyData: SurveyData,
    questions: QuestionData[],
    outcomes: OutcomeData[],
    sharing: SharingData,
  ): Observable<unknown> {
    const surveyLocales = surveyData ? buildSurveyLocales(surveyData) : [];
    const questionLocales = questions ? buildQuestionLocales(questions) : [];
    const outcomeLocales = outcomes ? buildOutcomeLocales(outcomes) : [];
    const surveySharingLocales = sharing ? buildSurveySharingLocales(sharing) : [];
    const outcomeSharingLocales = sharing?.outcomeSharing ? buildOutcomeSharingLocales(sharing.outcomeSharing) : [];

    const updates = translatorEntriesToUpdate(
      [...surveyLocales, ...questionLocales, ...outcomeLocales, ...surveySharingLocales, ...outcomeSharingLocales],
      true,
    );

    Object.entries(existingTranslations || {}).forEach(([key, value]) => {
      if (value && updates.hasOwnProperty(key)) {
        delete updates[key];
      }
    });

    return forkJoin([
      this.addSurveyLanguage(surveyKey, language.code),
      this.updateSurveyTranslation(surveyKey, language.code, updates),
    ]);
  }

  public loadSurveyLanguage(surveyKey: string, lcid: string): Observable<LanguageData> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<LanguageData>(`locales/${teamKey}/${surveyKey}/config/${lcid}`)
      .snapshotChanges()
      .pipe(mapObjectKey(), shareReplay({ refCount: true, bufferSize: 1 }));
  }

  public loadSurveyTranslation(surveyKey: string, lcid: string): Observable<TranslationData> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<TranslationData>(`locales/${teamKey}/${surveyKey}/strings/${lcid}`)
      .snapshotChanges()
      .pipe(mapObjectKey(), shareReplay({ refCount: true, bufferSize: 1 }));
  }

  updateSurveyTranslation(surveyKey: string, lcid: string, data: TranslationData) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const objRef = this.db.object<TranslationData>(`locales/${teamKey}/${surveyKey}/strings/${lcid}`);

    return from(objRef.update(data));
  }
}
