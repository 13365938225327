/**
 * Manages data data stored in the Firebase.
 *
 * @unstable
 */

import { Observable } from 'rxjs';

import { Store } from '@ngxs/store';

import { Injectable } from '@angular/core';

import { TriggerData } from '@shared/models/survey.model';

import { ObjectsManager } from '@shared/services/objects-manager.service';

import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { EmailsManager } from '@shared/services/emails-manager.service';

/**
 * Manages outcomes for an survey (handles copying etc.).
 */
@Injectable({
  providedIn: 'root',
})
export class TriggersManager extends ObjectsManager {
  readonly pathRoot = 'triggers';

  constructor(
    db: DatabaseWrapper,
    readonly store: Store,
    readonly em: EmailsManager,
  ) {
    super(db, store);
  }

  public triggerData(teamKey: string, surveyKey: string): Observable<TriggerData[]> {
    return this.orderedList<TriggerData>(`/${this.pathRoot}/${teamKey}/${surveyKey}`);
  }

  public addTrigger(trigger: TriggerData[] | TriggerData, index?: number) {
    return this.addItems(this.ref, trigger, index);
  }

  public removeTrigger(trigger: TriggerData) {
    return this.removeItems(this.ref, [trigger]);
  }

  public restoreTrigger(trigger: TriggerData) {
    return this.updatePriority(this.ref.child(trigger.$key), Math.abs(trigger.order));
  }

  public updateTrigger(triggerKey: string, data: Partial<TriggerData>) {
    return this.updateData(this.ref.child(triggerKey), data);
  }

  public archiveTrigger(triggerKey: string) {
    return this.updateData(this.ref.child(`${triggerKey}`), { archived: true });
  }

  public unarchiveTrigger(triggerKey: string) {
    return this.updateData(this.ref.child(`${triggerKey}`), { archived: null });
  }
}
