import { LanguageData2 } from '@shared/models/locale.model';

export function isOtherLanguage(language: LanguageData2 | string): boolean {
  return (typeof language === 'string' ? language : language?.code)?.length > 2;
}

export function mergeLanguageConfig(
  language: string,
  defaultData: LanguageData2[],
  customData?: LanguageData2,
): LanguageData2 {
  language ||= customData?.locale;

  return {
    ...(defaultData?.find(({ code }) => code === language) || {}),
    ...(customData || {}),
    code: language,
  } as LanguageData2;
}
