/**
 * Manages objects stored in the Firebase.
 *
 * @unstable
 */

import firebase from 'firebase/compat/app';
import 'firebase/database';

import { first, map, mapTo, switchMap, take } from 'rxjs/operators';

import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';

import { AngularFireAction } from '@angular/fire/compat/database';

import { Store } from '@ngxs/store';

import { AccountState } from '@shared/states/account.state';

import { OrderData } from '@shared/models/order.model';

import { flattenProps } from '@shared/utilities/object.utilities';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';

declare type Reference = firebase.database.Reference;
declare type DataSnapshot = firebase.database.DataSnapshot;

const setPriorityErrorHandler = (error) => {
  if (error) {
    console.error('setWithPriority error', error);
  }
};
export function firebaseSafe(data): any {
  return Object.keys(data)
    .filter((key) => !key.startsWith('$') && key !== '.priority')
    .reduce((dict, key) => {
      const value =
        data[key] !== null && typeof data[key] === 'object' && !(data[key] instanceof Date)
          ? firebaseSafe(data[key])
          : data[key] === undefined
            ? null
            : data[key] instanceof Date
              ? data[key].getTime()
              : data[key];

      return { ...dict, [key]: value };
    }, {});
}

export function unwrapOrderDataFromSnapshot(actions: AngularFireAction<DataSnapshot>[]) {
  return actions.map((action) => ({
    ...action.payload.exportVal(),
    $key: action.key,
    order: action.payload.getPriority(),
  }));
}

/**
 * Manages and modifies ordered data objects.
 */
export abstract class ObjectsManager<T extends OrderData = OrderData> {
  abstract readonly pathRoot: string;

  public surveyKey = new BehaviorSubject<string | null>(null);

  protected get ref() {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const surveyKey = this.surveyKey.getValue();
    return this.db.database.ref(`/${this.pathRoot}/${teamKey}/${surveyKey}`);
  }

  protected constructor(
    protected readonly db: DatabaseWrapper,
    protected readonly store: Store,
  ) {}

  getNewKey(): string {
    return this.db.createPushId();
  }

  protected orderedList<U = T>(pathOrRef): Observable<U[]> {
    return this.db
      .list(pathOrRef, (ref) => ref.orderByPriority().startAt(0))
      .snapshotChanges()
      .pipe(map(unwrapOrderDataFromSnapshot));
  }

  protected deletedItems(pathOrRef): Observable<T[]> {
    return this.db
      .list(pathOrRef, (ref) => ref.orderByPriority().endAt(0))
      .snapshotChanges()
      .pipe(map(unwrapOrderDataFromSnapshot));
  }

  protected generateOrders(entries: OrderData[], count: number, index?: number) {
    return OrderData.generateOrders(entries, count, index);
  }

  /**
   * Add existing items to the list.
   * (creates new database entry with priority)
   *
   * @param ref firebase database reference
   * @param data list item
   * @param index position in the list for first moving item
   */
  protected insertItems<U extends OrderData = T>(ref: Reference, data: U[], index?: number): Observable<string[]> {
    const $keys = data.map((entry) => entry.$key);
    const $allKeys = [...$keys];

    data = data.map((entry) => firebaseSafe(entry));

    if (!data.length) {
      return of([]);
    } else {
      return this.orderedList(ref).pipe(
        first(),

        switchMap((entries: T[]) => {
          const length = entries.length;
          const count = data.length;
          index = index == null ? length : index;

          return forkJoin(
            this.generateOrders(entries, count, index).map((order) => {
              if (order.index >= index && order.index < index + count) {
                return ref.child($keys.shift()).setWithPriority({ ...data.shift(), order: order.order }, order.order);
              } else {
                return ref.child(entries[order.index].$key).setPriority(order.order, setPriorityErrorHandler);
              }
            }),
          );
        }),

        mapTo($allKeys),
      );
    }
  }

  /**
   * Add new items to the list.
   * (creates new database entry with priority)
   *
   * @param ref firebase database reference
   * @param data list item
   * @param index position in the list for first moving item
   */
  protected addItems<U extends OrderData = T>(
    ref: Reference,
    data: U[] | U,
    index?: number,
  ): Observable<(string | null)[]> {
    if (!Array.isArray(data)) {
      data = [data];
    }

    const $keys = data.map((item) => item?.$insertKey || this.db.createPushId());
    const $allKeys = [...$keys];

    data = data.map((item) => firebaseSafe(item));

    return this.orderedList(ref).pipe(
      take(1),

      switchMap((entries: T[]) => {
        const count = (data as U[]).length;
        index ??= entries.length;

        if (entries.length < count) {
          const fullUpdate = this.generateOrders(entries, count, index).reduce((update, order) => {
            if (order.index >= index && order.index < index + count) {
              update[$keys.shift()] = { '.value': { ...data.shift(), order: order.order }, '.priority': order.order };
            }

            return update;
          }, {});

          entries.forEach(
            (entry) => (fullUpdate[entry.$key] = { '.value': firebaseSafe(entry), '.priority': entry.order }),
          );

          return ref.set(fullUpdate);
        } else {
          return forkJoin(
            this.generateOrders(entries, count, index).map((order) => {
              if (order.index >= index && order.index < index + count) {
                return ref.child($keys.shift()).setWithPriority((data as U[]).shift(), order.order);
              } else {
                return ref.child(entries[order.index].$key).setPriority(order.order, setPriorityErrorHandler);
              }
            }),
          );
        }
      }),

      map(() => $allKeys),
    );
  }

  /**
   * Copy items from the list.
   * (creates new database entry with priority)
   *
   * @param ref firebase database reference
   * @param data list item
   */
  protected copyItems(ref: Reference, data: T[]): Observable<string[]> {
    const $keys = data.map(() => this.db.createPushId() as string);
    const $allKeys = [...$keys];

    return this.orderedList(ref).pipe(
      first(),

      switchMap((entries: T[]) => {
        const length = entries.length;
        const count = data.length;
        let index = entries.findIndex((entry: T) => entry.$key === data.slice(-1)[0].$key) + 1;
        index = index == null ? length : index;

        return forkJoin(
          this.generateOrders(entries, count, index).map((order) => {
            if (order.index >= index && order.index < index + count) {
              return ref.child($keys.shift()).setWithPriority(firebaseSafe(data.shift()), order.order);
            } else {
              return ref.child(entries[order.index].$key).setPriority(order.order, setPriorityErrorHandler);
            }
          }),
        );
      }),
      mapTo($allKeys),
    );
  }

  /**
   * Moves items in the list.
   * (updates the priority of the database entry)
   *
   * @param ref firebase database reference
   * @param data list item
   * @param index position in the list for first moving item
   * @param extra data for objects
   */
  protected moveItems<U extends OrderData = T>(
    ref: Reference,
    data: U[],
    index?: number,
    extra?: { [key: string]: Partial<U> },
  ) {
    const $keys = data.map((item) => item.$key);
    const $allKeys = [...$keys];

    return this.orderedList(ref).pipe(
      first(),

      switchMap((entries: T[]) => {
        const length = entries.length;
        const count = data.length;
        index = index == null ? length : index;

        const updates = this.generateOrders(entries, count, index);

        return forkJoin(
          updates.map((order) => {
            if (order.index >= index && order.index < index + count) {
              const $key = $keys.shift();
              const update = extra && extra[$key];

              if (update) {
                return this.updateWithPriority(this.ref.child($key), update, order.order);
              } else {
                return ref.child($key).setPriority(order.order, setPriorityErrorHandler);
              }
            } else {
              const entry = entries[order.index - (order.index < index ? 0 : count)];
              return ref.child(entry.$key).setPriority(order.order, setPriorityErrorHandler);
            }
          }),
        );
      }),

      mapTo($allKeys),
    );
  }

  protected switchItems(ref: Reference, sourceKey: string, targetKey: string): Observable<void> {
    return this.orderedList(ref).pipe(
      take(1),
      switchMap((entries: OrderData[]) => {
        const sourceOrder = entries.find((entry) => entry.$key === sourceKey);
        const targetOrder = entries.find((entry) => entry.$key === targetKey);

        if (!sourceOrder || !targetOrder) {
          return of(void 0);
        }

        return combineLatest([
          this.updateWithPriority(ref.child(sourceKey), { order: targetOrder.order }, targetOrder.order),
          this.updateWithPriority(ref.child(targetKey), { order: sourceOrder.order }, sourceOrder.order),
        ]);
      }),
      mapTo(void 0),
    );
  }

  /**
   * Removes item from the list.
   * (updates the priority of the database entry)
   *
   * @param ref firebase database reference
   * @param data list item
   */
  protected removeItems<U extends OrderData = T>(ref: Reference, data: { $key: string }[]) {
    const $keys = data.map((item) => item.$key);

    return this.orderedList<U>(ref).pipe(
      first(),

      map((entries: U[]) => entries.filter((entry: U) => $keys.includes(entry.$key))),

      switchMap((entries: U[]) =>
        forkJoin(
          entries.map((entry: U) => ref.child(entry.$key).setPriority(-1 * entry.order, setPriorityErrorHandler)),
        ),
      ),

      mapTo($keys),
    );
  }

  /**
   * Restores deleted items back to the list.
   * (updates the priority of the database entry)
   *
   * @param ref firebase database reference
   * @param keys keys returned by removeItems
   */
  protected restoreItems(ref: Reference, keys: string[]) {
    return this.deletedItems(ref).pipe(
      first(),

      map((entries: T[]) => entries.filter((entry: T) => keys.includes(entry.$key))),

      switchMap((entries: T[]) =>
        forkJoin(
          entries.map((entry: T) => ref.child(entry.$key).setPriority(Math.abs(entry.order), setPriorityErrorHandler)),
        ),
      ),

      mapTo(keys),
    );
  }

  /**
   * Updates database entry priority.
   *
   * @param data
   * @param path
   * @param priority
   */
  protected updatePriority(ref: Reference, priority: string | number | null = null): Promise<void> {
    return ref.setPriority(priority, setPriorityErrorHandler);
  }

  /**
   * Updates database entry and set it's priority.
   *
   * @param ref
   * @param data
   * @param priority
   */
  protected async updateWithPriority(ref: Reference, data, priority: string | number): Promise<void> {
    const flattened = flattenProps({
      [ref.key as string]: {
        ...firebaseSafe(data),
        '.priority': priority,
      },
    });

    return (ref.parent as Reference).update(flattened);
  }

  /**
   * Updates database entry
   *
   * @param ref firebase database reference
   * @param data
   */
  protected updateData(ref: Reference, data) {
    return ref.update(firebaseSafe(data));
  }
}
