import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Selector, State, StateContext } from '@ngxs/store';

import { StreamAction } from '@shared/decorators/stream-action.decorator';
import { GetSurveysIndex } from '@shared/states/index/index-state.actions';
import { IndexApiService } from '@shared/services/index-api.service';
import { INDEX_STATE_TOKEN, IndexStateModel, SurveyIndex } from '@shared/states/index/index-state.models';
import { assertArray } from '@shared/utilities/array.utilities';

type StateModel = IndexStateModel;

type StateCtx = StateContext<StateModel>;

@State<StateModel>({
  name: INDEX_STATE_TOKEN,
  defaults: {
    surveys: [],
  },
})
@Injectable()
export class IndexState {
  @Selector()
  static surveys({ surveys }: StateModel): SurveyIndex[] {
    return assertArray(surveys);
  }

  constructor(private is: IndexApiService) {}

  @StreamAction(GetSurveysIndex)
  getSurveys({ patchState }: StateCtx): Observable<unknown> {
    return this.is.getSurveysIndex().pipe(
      map((surveys) => {
        patchState({ surveys });
      }),
    );
  }
}
