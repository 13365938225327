import { combineLatest } from 'rxjs';

import { Injectable } from '@angular/core';

import { NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';

import { getPollLocales } from '@poll/app/models/poll.models';

import { PrefsState } from '@shared/states/prefs.state';
import { LocalesManager } from '@shared/services/locales-manager.service';
import { LanguageData2, LocalesData, TranslationData } from '@shared/models/locale.model';
import { LOCALES_STATE_TOKEN, LocalesStateModel } from '@shared/states/locales/locales.models';

@State({
  name: LOCALES_STATE_TOKEN,
  defaults: {
    locales: { config: {}, strings: {} },
  },
})
@Injectable()
export class LocalesState implements NgxsOnInit {
  @Selector()
  static locales({ locales }: LocalesStateModel): LocalesData {
    return locales;
  }

  @Selector()
  static languages({ locales }: LocalesStateModel): LanguageData2[] {
    return Object.values(locales.config).sort((a, b) => a.name.localeCompare(b.name));
  }

  @Selector([PrefsState.language])
  static uiLocaleStrings({ locales }: LocalesStateModel, language: string): TranslationData {
    return locales.strings[language] || {};
  }

  constructor(private lm: LocalesManager) {}

  ngxsOnInit({ setState }: StateContext<LocalesStateModel>): void {
    combineLatest([
      this.lm.isoLocales(),
      this.lm.getAccreditedTranslations(),
      this.lm.getAutomaticTranslations(),
    ]).subscribe(([languages, accreditedTranslations, automaticUiTranslations]) => {
      const locales: LocalesData = languages.reduce(
        (localesData, language) => ({
          config: {
            ...localesData.config,
            [language.code]: {
              ...language,
              autoTranslatedUi: !!automaticUiTranslations?.[language.code] && !accreditedTranslations?.[language.code],
              autoTranslatedPoll:
                !!automaticUiTranslations?.[language.code] && !accreditedTranslations?.[language.code],
            } as LanguageData2,
          },
          strings: {
            ...localesData.strings,
            [language.code]: {
              ...(automaticUiTranslations?.[language.code] || {}),
              ...(getPollLocales(language.code) || {}),
              ...(accreditedTranslations?.[language.code] || {}),
            },
          },
        }),
        { config: {}, strings: {} },
      );

      setState({ locales });
    });
  }
}
