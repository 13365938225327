/**
 * Survey question types.
 *
 * @unstable
 */

import { Answers } from '@shared/enums/answers.enum';

import { ChoiceItemData, QuestionData, SliderValuesData } from '@shared/models/survey.model';
import { expandProps, flattenProps } from '@shared/utilities/object.utilities';
import { HelpSubject } from '@shared/modules/help-center/help-subject.enum';
import { shuffleArray } from '@shared/utilities/array.utilities';

interface SettingsData {
  key: string;
  type: string;
  empty?: any;
  default?: any;
}

export enum Questions {
  // Question categories
  UNKNOWN = '',
  INFO = 'info',
  FREE = 'free',
  INPUT = 'input',
  CHOICE = 'choice',
  SLIDER = 'slider',
  GROUP = 'group',
  SCORED = 'scored',

  // Specific question types
  INFO_TEXT = 'info-text',
  FREE_TEXT = 'free-text',

  SLIDER_1D = 'slider-1d',
  SLIDER_1R = 'slider-1r',
  SLIDER_1V = 'slider-1v',
  SLIDER_2D = 'slider-2d',
  SLIDER_NPS = 'slider-nps',
  SLIDER_E_NPS = 'slider-e-nps',

  // @deprecated: use CHOICE_MULTI
  CHOICE_TEXT = 'choice-text',
  CHOICE_IMAGE = 'choice-image',

  // @deprecated: use CHOICE_SINGLE
  CHOICE_RADIO = 'choice-radio',
  CHOICE_CHECK = 'choice-check',
  CHOICE_CLOUD = 'choice-cloud',

  CHOICE_MULTI = 'choice-multi',
  CHOICE_SINGLE = 'choice-single',
  CHOICE_PICTURE = 'choice-picture',

  INPUT_URL = 'input-url',
  INPUT_EMAIL = 'input-email',
  INPUT_PHONE = 'input-phone',
  INPUT_NUMBER = 'input-number',
  INPUT_STRING = 'input-string',
  INPUT_NUMERIC = 'input-numeric',
  INPUT_ADDRESS = 'input-address',
  INPUT_CHECKBOX = 'input-checkbox',
  INPUT_DROPDOWN = 'input-dropdown',

  FILE_UPLOAD = 'file-upload',

  GROUP_LIST = 'group-list',
  GROUP_CARDS = 'group-cards',
  GROUP_SCORED = 'group-scored',

  SURVEY_USER_RATING = 'survey-user-rating',

  AI_INTERVIEWER = 'ai-interviewer',

  ESKO_WHY_FINDER = 'esko-why-finder',
}

/* eslint-disable @typescript-eslint/no-namespace */
export namespace Questions {
  export const INPUT_NUMERIC_LIMIT = 500;

  // Icons for the question types
  export const QuestionIcons: { [Question in Questions]?: string } = {
    [Questions.INFO_TEXT]: 'editor_question_info',
    [Questions.FREE_TEXT]: 'editor_question_textlong',
    [Questions.SLIDER_1V]: 'editor_question_zeroslider',
    [Questions.SLIDER_1D]: 'editor_question_slider',
    [Questions.SLIDER_1R]: 'editor_question_range',
    [Questions.SLIDER_2D]: 'editor_question_2d',
    [Questions.SLIDER_NPS]: 'editor_question_nps',
    [Questions.SLIDER_E_NPS]: 'editor_question_e_nps',
    [Questions.CHOICE_TEXT]: 'editor_question_choice',
    [Questions.CHOICE_IMAGE]: 'editor_question_choice',
    [Questions.CHOICE_RADIO]: 'editor_question_choice',
    [Questions.CHOICE_CHECK]: 'editor_question_choice',
    [Questions.CHOICE_CLOUD]: 'editor_question_choice',
    [Questions.CHOICE_SINGLE]: 'editor_question_single_choice',
    [Questions.CHOICE_MULTI]: 'editor_question_choice',
    [Questions.CHOICE_PICTURE]: 'editor_question_picture_choice',
    [Questions.INPUT_URL]: 'editor_question_inputurl',
    [Questions.INPUT_EMAIL]: 'editor_question_inputemail',
    [Questions.INPUT_PHONE]: 'editor_question_inputphone',
    [Questions.INPUT_NUMBER]: 'editor_question_inputnumber',
    [Questions.INPUT_STRING]: 'editor_question_text',
    [Questions.INPUT_ADDRESS]: 'editor_question_inputaddress',
    [Questions.INPUT_CHECKBOX]: 'editor_question_checkbox',
    [Questions.INPUT_DROPDOWN]: 'editor_question_dropdown',
    [Questions.INPUT_NUMERIC]: 'editor_question_numeric',
    [Questions.FILE_UPLOAD]: 'editor_question_upload',
    [Questions.GROUP_LIST]: 'editor_question_group',
    [Questions.GROUP_CARDS]: 'editor_question_group',
    [Questions.GROUP_SCORED]: 'editor_question_group',
    [Questions.AI_INTERVIEWER]: 'editor_question_ai_interviewer',
    [Questions.ESKO_WHY_FINDER]: 'editor_question_ai_interviewer',
  };

  // Icons for the question types
  export const QuestionIconColors: { [Question in Questions]?: string } = {
    [Questions.INFO_TEXT]: 'info',
    [Questions.FREE_TEXT]: 'text',
    [Questions.SLIDER_1V]: 'vol-slider',
    [Questions.SLIDER_1D]: 'slider',
    [Questions.SLIDER_1R]: 'range-slider',
    [Questions.SLIDER_2D]: 'slider2d',
    [Questions.SLIDER_NPS]: 'nps',
    [Questions.SLIDER_E_NPS]: 'e-nps',
    [Questions.CHOICE_PICTURE]: 'choice',
    [Questions.CHOICE_TEXT]: 'choice',
    [Questions.CHOICE_IMAGE]: 'choice',
    [Questions.CHOICE_RADIO]: 'choice',
    [Questions.CHOICE_CHECK]: 'choice',
    [Questions.CHOICE_CLOUD]: 'choice',
    [Questions.INPUT_URL]: 'url',
    [Questions.INPUT_EMAIL]: 'email',
    [Questions.INPUT_PHONE]: 'phone',
    [Questions.INPUT_NUMBER]: 'number',
    [Questions.INPUT_STRING]: 'text',
    [Questions.INPUT_ADDRESS]: 'text',
    [Questions.INPUT_CHECKBOX]: 'checkbox',
    [Questions.INPUT_DROPDOWN]: 'dropdown',
    [Questions.INPUT_NUMERIC]: 'num-dropdown',
    [Questions.FILE_UPLOAD]: 'file-upload',
    [Questions.GROUP_LIST]: 'group',
    [Questions.GROUP_CARDS]: 'group',
    [Questions.GROUP_SCORED]: 'group',
    [Questions.AI_INTERVIEWER]: 'interviewer',
    [Questions.ESKO_WHY_FINDER]: 'interviewer',
  };

  export const QuestionHelp: { [Question in Questions]?: HelpSubject } = {
    [Questions.INFO_TEXT]: HelpSubject.QuestionInfoText,
    [Questions.FREE_TEXT]: HelpSubject.QuestionFreeText,
    [Questions.SLIDER_1V]: HelpSubject.QuestionSliderVolume,
    [Questions.SLIDER_1D]: HelpSubject.QuestionSlider1D,
    [Questions.SLIDER_1R]: HelpSubject.QuestionSliderRange,
    [Questions.SLIDER_2D]: HelpSubject.QuestionSlider2D,
    [Questions.SLIDER_NPS]: HelpSubject.QuestionSliderNPS,
    [Questions.SLIDER_E_NPS]: HelpSubject.QuestionSliderENPS,
    [Questions.CHOICE_TEXT]: HelpSubject.QuestionChoiceText,
    [Questions.CHOICE_SINGLE]: HelpSubject.QuestionChoiceText,
    [Questions.CHOICE_MULTI]: HelpSubject.QuestionChoiceTextMulti,
    [Questions.CHOICE_PICTURE]: HelpSubject.QuestionChoicePicture,
    [Questions.INPUT_URL]: HelpSubject.QuestionInputUrl,
    [Questions.INPUT_EMAIL]: HelpSubject.QuestionInputEmail,
    [Questions.INPUT_PHONE]: HelpSubject.QuestionInputPhone,
    [Questions.INPUT_NUMBER]: HelpSubject.QuestionInputNumber,
    [Questions.INPUT_STRING]: HelpSubject.QuestionFreeText,
    [Questions.INPUT_ADDRESS]: HelpSubject.QuestionInputAddress,
    [Questions.INPUT_CHECKBOX]: HelpSubject.QuestionInputCheckbox,
    [Questions.INPUT_DROPDOWN]: HelpSubject.QuestionInputDropdown,
    [Questions.INPUT_NUMERIC]: HelpSubject.QuestionInputNumeric,
    [Questions.FILE_UPLOAD]: HelpSubject.QuestionFileUpload,
    [Questions.GROUP_CARDS]: HelpSubject.QuestionGroupCards,
    [Questions.GROUP_SCORED]: HelpSubject.QuestionGroupScored,
    [Questions.AI_INTERVIEWER]: HelpSubject.QuestionAIInterviewer,
    [Questions.ESKO_WHY_FINDER]: HelpSubject.QuestionEskoWhyFinder,
  };

  export const AnswerTypes: { [Question in Questions]?: Answers } = {
    [Questions.INFO_TEXT]: Answers.BOOLEAN,
    [Questions.FREE_TEXT]: Answers.STRING,
    [Questions.SLIDER_1D]: Answers.NUMBER,
    [Questions.SLIDER_1V]: Answers.NUMBER,
    [Questions.SLIDER_1R]: Answers.ARRAY_NUMBER,
    [Questions.SLIDER_2D]: Answers.ARRAY_NUMBER,
    [Questions.SLIDER_NPS]: Answers.NUMBER,
    [Questions.SLIDER_E_NPS]: Answers.NUMBER,
    [Questions.CHOICE_TEXT]: Answers.ARRAY_STRING,
    [Questions.CHOICE_IMAGE]: Answers.ARRAY_STRING,
    [Questions.CHOICE_RADIO]: Answers.ARRAY_STRING,
    [Questions.CHOICE_CHECK]: Answers.ARRAY_STRING,
    [Questions.CHOICE_CLOUD]: Answers.ARRAY_STRING,
    [Questions.CHOICE_SINGLE]: Answers.ARRAY_STRING,
    [Questions.CHOICE_MULTI]: Answers.ARRAY_STRING,
    [Questions.CHOICE_PICTURE]: Answers.ARRAY_STRING,
    [Questions.INPUT_URL]: Answers.STRING,
    [Questions.INPUT_EMAIL]: Answers.EMAIL,
    [Questions.INPUT_PHONE]: Answers.PHONE,
    [Questions.INPUT_NUMBER]: Answers.STRING,
    [Questions.INPUT_STRING]: Answers.STRING,
    [Questions.INPUT_ADDRESS]: Answers.STRING,
    [Questions.INPUT_CHECKBOX]: Answers.STRING,
    [Questions.INPUT_DROPDOWN]: Answers.ARRAY_STRING,
    [Questions.INPUT_NUMERIC]: Answers.STRING,
    [Questions.FILE_UPLOAD]: Answers.STRING,
    [Questions.GROUP_LIST]: Answers.BOOLEAN,
    [Questions.GROUP_CARDS]: Answers.BOOLEAN,
    [Questions.GROUP_SCORED]: Answers.BOOLEAN,
    [Questions.SURVEY_USER_RATING]: Answers.NUMBER,
    [Questions.AI_INTERVIEWER]: Answers.STRING,
    [Questions.ESKO_WHY_FINDER]: Answers.STRING,
  };

  /**
   * TODO: Add empty condition for other settings?
   * TODO: This is a good place to add default values.
   *       If they are needed somewhere?
   */
  export const settings: SettingsData[] = [
    { key: 'sliderValues/initial', type: 'number' },
    { key: 'sliderValues/min', type: 'number' },
    { key: 'sliderValues/max', type: 'number' },
    { key: 'sliderValues/step', type: 'number' },

    { key: 'sliderLabels/axis', type: 'string', empty: '' },
    { key: 'sliderLabels/max', type: 'string', empty: '' },
    { key: 'sliderLabels/min', type: 'string', empty: '' },
    { key: 'sliderLabels/visible', type: 'boolean' },

    { key: 'sliderValuesX/initial', type: 'number' },
    { key: 'sliderValuesX/min', type: 'number' },
    { key: 'sliderValuesX/max', type: 'number' },
    { key: 'sliderValuesX/step', type: 'number' },

    { key: 'sliderLabelsX/axis', type: 'string', empty: '' },
    { key: 'sliderLabelsX/max', type: 'string', empty: '' },
    { key: 'sliderLabelsX/min', type: 'string', empty: '' },
    { key: 'sliderLabelsX/visible', type: 'boolean' },

    { key: 'sliderValuesY/initial', type: 'number' },
    { key: 'sliderValuesY/min', type: 'number' },
    { key: 'sliderValuesY/max', type: 'number' },
    { key: 'sliderValuesY/step', type: 'number' },

    { key: 'sliderLabelsY/axis', type: 'string', empty: '' },
    { key: 'sliderLabelsY/max', type: 'string', empty: '' },
    { key: 'sliderLabelsY/min', type: 'string', empty: '' },
    { key: 'sliderLabelsY/visible', type: 'boolean' },
  ];

  /**
   * Returns a question with normalized values
   * by comparing them to predefined value from "Questions.settings".
   *
   *   `{ sliderLablesY: { axis: '', min: undefined } } -> { sliderLablesY: { axis: null, min: null } }`
   *
   * With optional separator argument returns flattened values
   * which can be used with firebase `ref.update()`.
   *
   *   `{ sliderLablesY: { axis: '', min: undefined } } -> { 'sliderLablesY/axis': null, 'sliderLablesY/min': null }`
   *
   */
  export const value = (question: QuestionData, separator?: string): any => {
    const flattened = flattenProps(question, separator);
    const result = Questions.settings
      .filter((setting) => setting.key in flattened)
      .reduce((data, setting) => {
        const val =
          flattened[setting.key] === setting.empty || flattened[setting.key] === undefined
            ? null
            : flattened[setting.key];

        return { ...data, [setting.key]: val };
      }, flattened);

    return typeof separator === 'string' ? result : expandProps(result, separator);
  };

  export const getGroup = (question: QuestionData, questions: QuestionData[]): QuestionData | undefined => {
    if (question && question.group && !Questions.group(question)) {
      return questions.find(({ $key }) => $key === question.group);
    }
  };

  export const groupQuestions = (question: QuestionData, questions: QuestionData[]): QuestionData[] =>
    questions.filter((groupQuestion) => !Questions.group(groupQuestion) && groupQuestion.group === question.$key);

  export const isPromo = (type: Questions): boolean => type === Questions.FILE_UPLOAD;

  export const choices = [
    Questions.CHOICE_MULTI,
    Questions.CHOICE_SINGLE,
    Questions.CHOICE_TEXT,
    Questions.CHOICE_PICTURE,
  ];

  export const isChoice = (question: QuestionData): boolean => choices.includes(question?.type);

  export const isNps = (question: Partial<QuestionData>): boolean =>
    [Questions.SLIDER_NPS, Questions.SLIDER_E_NPS].includes(question?.type);

  export const group = (question: Partial<QuestionData>): boolean =>
    [Questions.GROUP_LIST, Questions.GROUP_CARDS, Questions.GROUP_SCORED].includes(question?.type);

  export const info = (question: Partial<QuestionData>): boolean =>
    question?.type === Questions.INFO_TEXT || group(question);

  export const jumping = (question: QuestionData, answer?: string): boolean =>
    [
      Questions.SLIDER_1V,
      Questions.SLIDER_1D,
      Questions.SLIDER_2D,
      Questions.SLIDER_NPS,
      Questions.SLIDER_E_NPS,
      Questions.CHOICE_TEXT,
      Questions.CHOICE_CHECK,
      Questions.CHOICE_SINGLE,
      Questions.CHOICE_PICTURE,
      Questions.INPUT_NUMERIC,
      Questions.INPUT_DROPDOWN,
    ].includes(question.type) &&
    ((question.type !== Questions.CHOICE_SINGLE && question.type !== Questions.CHOICE_PICTURE) ||
      ((question.choiceLimit || 1) === 1 && !(answer || '').includes('other=')));

  export const scorable = (question: QuestionData): boolean =>
    [
      Questions.SLIDER_1V,
      Questions.SLIDER_1D,
      Questions.SLIDER_1R,
      Questions.SLIDER_2D,
      Questions.SLIDER_NPS,
      Questions.SLIDER_E_NPS,
      Questions.CHOICE_TEXT,
      Questions.CHOICE_SINGLE,
      Questions.CHOICE_MULTI,
      Questions.CHOICE_PICTURE,
      Questions.INPUT_DROPDOWN,
    ].includes(question.type);

  export const interviewable = (question: QuestionData): boolean =>
    [
      Questions.SLIDER_1V,
      Questions.SLIDER_1D,
      Questions.SLIDER_1R,
      Questions.SLIDER_2D,
      Questions.SLIDER_NPS,
      Questions.SLIDER_E_NPS,
      Questions.CHOICE_TEXT,
      Questions.CHOICE_SINGLE,
      Questions.CHOICE_MULTI,
      Questions.CHOICE_PICTURE,
      Questions.INPUT_DROPDOWN,
    ].includes(question.type);

  export const integratable = (question: QuestionData): boolean => !Questions.info(question);

  export const slider = (question: QuestionData): boolean =>
    [
      Questions.SLIDER_1V,
      Questions.SLIDER_1D,
      Questions.SLIDER_1R,
      Questions.SLIDER_2D,
      Questions.SLIDER_NPS,
      Questions.SLIDER_E_NPS,
    ].includes(question.type);

  export const shufflable = (question: QuestionData): boolean =>
    [Questions.CHOICE_PICTURE, Questions.CHOICE_SINGLE, Questions.CHOICE_MULTI].includes(question.type);

  export const zScorable = (question: QuestionData): boolean =>
    [Questions.SLIDER_1D, Questions.SLIDER_1V, Questions.SLIDER_2D].includes(question.type);

  export const category = (question: QuestionData, cat?: string): boolean | string => {
    if (question && question.type) {
      if (!cat) {
        return question.type.split('-')[0].replace('free', 'input');
      } else if (cat === 'scored') {
        return question.type === Questions.SLIDER_1D || question.type === Questions.SLIDER_2D;
      } else {
        return question.type.split('-')[0].replace('free', 'input') === cat;
      }
    }

    return false;
  };

  export const potentiallyIdentifiable = (question: QuestionData): boolean =>
    [
      Questions.FREE_TEXT,
      Questions.INPUT_URL,
      Questions.INPUT_EMAIL,
      Questions.INPUT_PHONE,
      Questions.INPUT_NUMBER,
      Questions.INPUT_STRING,
      Questions.INPUT_ADDRESS,
      Questions.FILE_UPLOAD,
      Questions.AI_INTERVIEWER,
      Questions.ESKO_WHY_FINDER,
    ].includes(question?.type);

  export const attachedKeys = (question: QuestionData, questions: QuestionData[], checkScored?: boolean): string[] => {
    const keys = [question.$key];
    const isGroup = group(question);

    checkScored = checkScored && zScorable(question);

    if (isGroup) {
      keys.push(...groupQuestions(question, questions).map(({ $key }) => $key));
    } else if (checkScored) {
      const inGroup = getGroup(question, questions);

      if (inGroup && inGroup.type === Questions.GROUP_SCORED) {
        const scoredItems = groupQuestions(inGroup, questions)
          .filter(({ $key, type }) => $key !== question.$key && question.type === type)
          .map(({ $key }) => $key);

        keys.push(...scoredItems);
      }
    }

    return keys;
  };

  export const shuffleChoices = (choiceList: ChoiceItemData[]): ChoiceItemData[] => {
    choiceList = [...(choiceList || [])];
    const otherIdx = choiceList.findIndex((choice) => choice.$key === 'other');
    let otherChoice: ChoiceItemData;

    if (otherIdx > -1) {
      otherChoice = choiceList.splice(otherIdx, 1)[0];
    }

    choiceList = shuffleArray(choiceList);

    return [...shuffleArray(choiceList), ...(otherChoice ? [otherChoice] : [])];
  };

  export const generateNumericValues = (sliderValues: SliderValuesData): number[] => {
    const options: number[] = [];
    let min = sliderValues?.min || 0;
    const max = sliderValues?.max || 100;
    const step = sliderValues?.step || 1;

    for (; min <= max; min += step) {
      options.push(min);
    }

    return options;
  };

  export const toArray = () =>
    Object.keys(Questions)
      .map((key) => Questions[key])
      .filter((prop) => Object.getPrototypeOf(prop) === String.prototype)
      .filter((stringProp: string) => stringProp.includes('-'));
}
