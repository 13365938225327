/**
 * Property (chips) types.
 *
 * @unstable
 */

export type Property = UserProperty | TeamProperty | ContactProperty | SurveyProperty | OtherProperty;

// prefs/<teamKey>/<userId>/properties
export enum UserProperty {
  FIRST_NAME = 'my_first_name',
  LAST_NAME = 'my_last_name',
  EMAIL = 'my_email',
  PHONE_NUMBER = 'my_phone_number',
  URL = 'my_organization_page',
  FACEBOOK = 'my_facebook_page',
  LINKEDIN = 'my_linkedin_page',
  TWITTER = 'my_twitter_handle',
}

// teams/<teamKey>
export enum TeamProperty {
  NAME = 'org_name',
  URL = 'org_url',
  FACEBOOK = 'org_facebook',
  LINKEDIN = 'org_linkedin',
  TWITTER = 'org_twitter',
}

// contacts/<teamKey>/columns
export enum ContactProperty {
  PROPERTIES = 'contact_properties',
  NAME = 'contact_name',
  FIRST_NAME = 'contact_first_name',
  LAST_NAME = 'contact_last_name',
  EMAIL = 'contact_email',
}

export enum SurveyProperty {
  NAME = 'survey_name',
  SHARE_NAME = 'survey_share_name',
  ANSWERS = 'survey_answers',
  OUTCOMES = 'survey_outcomes',
  HASHTAGS = 'survey_share_hashtags',
  DESCRIPTION = 'survey_description',
  LINK = 'survey_link',
  ACCESSIBLE = 'survey_accessible_link',
  QUESTION_NPS = 'question_nps',
}

export enum OtherProperty {
  START_BUTTON = 'start_button',
  MATCH_PERCENTAGE = 'match_percentage',
  OUTCOME_TITLE = 'outcome_title',
  OUTCOME_DESCRIPTION = 'outcome_description',
  OUTCOME_CORRECT_ANSWERS = 'outcome_correct_answers',
  OUTCOME_MAX_CORRECT_ANSWERS = 'outcome_max_correct_answers',
  TOP_OUTCOME_TITLE = 'top_outcome_title',
  TOP_OUTCOME_DESCRIPTION = 'top_outcome_description',
}

export const staticProperties: Property[] = [
  ...Object.values(UserProperty),
  ...Object.values(TeamProperty),
  ...Object.values(ContactProperty),
  ...Object.values(SurveyProperty),
];

export const surveyProperties: Property[] = [
  TeamProperty.NAME,
  SurveyProperty.NAME,
  SurveyProperty.DESCRIPTION,
  OtherProperty.START_BUTTON,
];

export const contactProperties: Property[] = [
  TeamProperty.NAME,
  ContactProperty.PROPERTIES,
  ContactProperty.EMAIL,
  ContactProperty.NAME,
  ContactProperty.FIRST_NAME,
  ContactProperty.LAST_NAME,
];

export const outcomeProperties: Property[] = [
  TeamProperty.NAME,
  SurveyProperty.NAME,
  SurveyProperty.DESCRIPTION,
  OtherProperty.START_BUTTON,
  OtherProperty.MATCH_PERCENTAGE,
  OtherProperty.OUTCOME_TITLE,
  OtherProperty.OUTCOME_DESCRIPTION,
  OtherProperty.OUTCOME_CORRECT_ANSWERS,
  OtherProperty.OUTCOME_MAX_CORRECT_ANSWERS,
];

export const surveyNotificationProperties: Property[] = [
  SurveyProperty.NAME,
  SurveyProperty.DESCRIPTION,
  SurveyProperty.ANSWERS,
  SurveyProperty.OUTCOMES,
  SurveyProperty.HASHTAGS,
  SurveyProperty.SHARE_NAME,
  SurveyProperty.LINK,
];

export const surveyEmailProperties: Property[] = [
  SurveyProperty.NAME,
  SurveyProperty.DESCRIPTION,
  SurveyProperty.LINK,
  SurveyProperty.HASHTAGS,
  SurveyProperty.SHARE_NAME,
];

export const linkProperties: Property[] = [SurveyProperty.LINK, SurveyProperty.QUESTION_NPS];

export const fallbackLinkProperties: any[] = [
  'personal_survey_link',
  'public_survey_link',
  'survey_link',
  'personal_link',
  SurveyProperty.QUESTION_NPS,
];

/**
 * ChipPattern used to identify properties
 */
export const chipPattern = /({.*?})/g;

/**
 * Fallback properties used for unavailable properties
 */
export enum PropertyFallbacks {
  EMPTY = 'Empty',
  POWERED_BY = 'Powered by ZEF',
  START = 'START',
  MATCH_PERCENTAGE = '88%',
  OUTCOME_CORRECT_ANSWERS = '7',
  OUTCOME_MAX_CORRECT_ANSWERS = '10',
}
