import { ElementRef, QueryList } from '@angular/core';

export function getNextElementFromList(
  el: HTMLElement,
  list: QueryList<ElementRef<HTMLElement>>,
): HTMLElement | undefined {
  const idx = getElementIndexFromList(el, list) + 1;

  return list.get(idx)?.nativeElement;
}

export function getPreviousElementFromList(
  el: HTMLElement,
  list: QueryList<ElementRef<HTMLElement>>,
): HTMLElement | undefined {
  const idx = getElementIndexFromList(el, list) - 1;

  return list.get(idx)?.nativeElement;
}

export function getElementIndexFromList(el: HTMLElement, list: QueryList<ElementRef<HTMLElement>>): number {
  return list.toArray().findIndex((elRef) => elRef.nativeElement === el);
}

export function getPositionOfChildElement(child: HTMLElement, ancestor: HTMLElement): number {
  let top = child.offsetTop;
  let parent = child.offsetParent as HTMLElement;

  while (parent && parent !== ancestor) {
    top += parent.offsetTop;
    parent = parent.offsetParent as HTMLElement;
  }

  return top;
}

export function downloadObjectAsJson(exportObj: object, exportName: string) {
  const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj, null, 2));
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', exportName + '.json');
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export function loadJavascript(url: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;

    script.onload = () => resolve();
    script.onerror = (e) => reject(e);

    document.getElementsByTagName('head')[0].appendChild(script);
  });
}
