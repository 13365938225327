/**
 * Email related types.
 *
 * @unstable
 */

export enum Delivery {
  WAITING = 0,
  QUEUED = 10,
  PROCESSED = 11,
  DEFERRED = 12,
  DELIVERED = 13,
  DROPPED = 21,
  BOUNCE = 22,
  DECLINED = 50,
  MALFORMED = 51,
}

export class Email {
  static readonly NEW: string = 'new';
  static readonly DRAFT: string = 'draft';
  static readonly READY: string = 'ready';
}
