export function isInFuture(date: Date, compareTo: Date = new Date()): boolean {
  return date.getTime() > compareTo.getTime();
}

export function isInPast(date: Date, compareTo: Date = new Date()): boolean {
  return date.getTime() < compareTo.getTime();
}

export function isSameDay(d1: Date, d2: Date): boolean {
  return d1?.toDateString() === d2?.toDateString();
}
