import { ChangeDetectionStrategy, Component, HostListener, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import {
  SurveyModel,
  templateAuthorIntroLink,
  templateAuthorVideoLink,
  templatePlayerLink,
  templateReportLink,
} from '@shared/models/survey.model';
import { AccountState } from '@shared/states/account.state';
import { GoToSignUp } from '@shared/states/auth.actions';
import { PrefsState } from '@shared/states/prefs.state';
import { CreateSurvey } from '@shared/states/survey.actions';
import { TemplatesState } from '@shared/states/templates.state';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'discover-survey',
  templateUrl: './discover-survey.dialog.html',
  styleUrls: ['./discover-survey.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscoverSurvey implements OnInit, OnDestroy {
  private history: any = {};

  @Input() templateKey: string;

  @Input() templates: SurveyModel[] = [];
  @Input() showNavigation: boolean = true;
  @Input() startView: 'intro' | 'report' = 'intro';

  @Select(PrefsState.isMobile)
  readonly isMobile$: Observable<boolean>;

  activeTemplateKey = new BehaviorSubject(null);
  activeContentView = new BehaviorSubject(null);

  constructor(
    readonly store: Store,
    @Optional() readonly dialogRef: MatDialogRef<DiscoverSurvey>,
  ) {}

  ngOnInit() {
    this.history = {
      url: window.location.pathname,
      title: window.document.title,
      state: window.history.state,
    };

    this.changeTemplate(this.templateKey);
    this.activeContentView.next('intro');
  }

  ngOnDestroy() {
    if (this.dialogRef && window.history && window.history.replaceState) {
      window.history.replaceState(this.history.state, this.history.title, this.history.url);
    }
  }

  videoUrl(model: SurveyModel) {
    return templateAuthorVideoLink(model.template);
  }

  reportUrl(model: SurveyModel) {
    return templateReportLink(model.template);
  }

  surveyUrl(model: SurveyModel) {
    return templatePlayerLink(model.template);
  }

  introContent(model: SurveyModel) {
    return templateAuthorIntroLink(model.template);
  }

  changeTemplate(templateKey: string) {
    if (this.templates.length === 0) {
      return;
    }

    if (this.dialogRef) {
      if (window.history && window.history.pushState) {
        const template = this.templates.find((t) => t.template.templateKey === templateKey)?.template;

        if (!template) {
          return;
        }

        const title = template.name || '';

        if (template.language !== 'fi') {
          const url =
            '/discover/' +
            template.name
              .toLowerCase()
              .replace(/-|\(|\)/g, '')
              .replace(/ /g, '-') +
            '/';

          window.history.pushState({}, title, url);
        } else {
          const url =
            '/fi/inspiroidu/' +
            template.name
              .toLowerCase()
              .replace(/-|\(|\)/g, '')
              .replace(/ /g, '-') +
            '/';

          window.history.pushState({}, title, url);
        }
      } else {
        this.store.dispatch(new Navigate(['/discover', this.templateKey], void 0, { skipLocationChange: true }));
      }
    }

    this.activeTemplateKey.next(templateKey);
    this.activeContentView.next('intro');
  }

  changeContentView(value: 'intro' | 'report') {
    this.activeContentView.next(value);
  }

  selectTemplate(templateCode: string) {
    if (templateCode) {
      const isMobile = this.store.selectSnapshot(PrefsState.isMobile);

      if (isMobile) {
        this.store.dispatch(new GoToSignUp());
      } else {
        const teamKey = this.store.selectSnapshot(AccountState.teamKey);
        const { template } = this.store.selectSnapshot(TemplatesState.activeTemplate);
        const templateKey = template.templateKey;
        const fromTeam = template.teamKey;
        const name = template.name;

        this.store.dispatch(
          new CreateSurvey({ command: 'create_from_blueprint', name, teamKey, fromTeam, templateKey }),
        );
      }
    }

    if (this.dialogRef) {
      this.dialogRef.close(templateCode);
    }
  }

  surveyModel(templateKey: string) {
    return this.templates.find(({ template }) => template.templateKey === templateKey);
  }

  @HostListener('document:keyup.ArrowRight')
  gotoNext() {
    const templateKey = this.nextTemplate();
    if (templateKey) {
      this.changeTemplate(templateKey);
    }
  }

  @HostListener('document:keyup.ArrowLeft')
  gotoPrev() {
    const templateKey = this.prevTemplate();
    if (templateKey) {
      this.changeTemplate(templateKey);
    }
  }

  nextTemplate() {
    const templateKey = this.activeTemplateKey.value;
    const templates = this.templates;
    const index = templates.findIndex((t) => t.template.templateKey === templateKey);
    const template = templates[index + 1];

    return template ? template.template.templateKey : null;
  }

  prevTemplate() {
    const templateKey = this.activeTemplateKey.value;
    const templates = this.templates;
    const index = templates.findIndex((t) => t.template.templateKey === templateKey);
    const template = templates[index - 1];

    return template ? template.template.templateKey : null;
  }
}
