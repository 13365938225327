import { FolderModel } from '@shared/models/survey.model';

export class GetFolders {
  static readonly type = '[Folders] Get Folders';
}

export class CreateFolder {
  static readonly type = '[Folders] Create Folder';
  constructor(readonly data: Partial<FolderModel>) {}
}

export class UpdateFolder {
  static readonly type = '[Folders] Update Folder';
  constructor(
    readonly folderKey: string,
    readonly data: Partial<FolderModel>,
  ) {}
}

export class ResetFolders {
  static readonly type = '[Folders] Reset Folders';
}

export class DeleteFolder {
  static readonly type = '[Folders] Delete Folder';
  constructor(readonly folderKey: string) {}
}

export class MoveToFolder {
  static readonly type = '[Folders] Move To Folder';
  constructor(
    readonly folderKey: string,
    readonly surveyKeys: string[],
  ) {}
}

export class RemoveFromFolder {
  static readonly type = '[Folders] Remove From Folder';
  constructor(
    readonly folderKey: string,
    readonly surveys: string[],
  ) {}
}

export class ToggleSurveyHover {
  static readonly type = '[Folders] Toggle Survey Hover';
  constructor(readonly surveyKey?: string) {}
}

export class ToggleSurveySelect {
  static readonly type = '[Folders] Toggle Survey Select';
  constructor(readonly surveyKey: string) {}
}

export class OpenPanel {
  static readonly type = '[Folders] Open Panel';
}

export class SelectSurveys {
  static readonly type = '[Folders] Select Surveys';
  constructor(readonly selected: string[]) {}
}

export class DeselectSurveys {
  static readonly type = '[Folders] Deselect Surveys';
}
