/**
 * Service names and icons.
 *
 * @unstable
 */

import { NameIconMap } from '@shared/models/utility.model';

export enum SocialService {
  Facebook = 'facebook',
  Twitter = 'twitter',
  Whatsapp = 'whatsapp',
  Linkedin = 'linkedin',
  Email = 'email',
}

export class Services {
  static readonly social: NameIconMap<SocialService>[] = [
    { id: SocialService.Facebook, name: 'Facebook', icon: 'facebook' },
    { id: SocialService.Twitter, name: 'Twitter', icon: 'twitter' },
    { id: SocialService.Whatsapp, name: 'WhatsApp', icon: 'whatsapp' },
    { id: SocialService.Linkedin, name: 'LinkedIn', icon: 'linkedin' },
    { id: SocialService.Email, name: 'Email', icon: 'social_email' },
  ];

  static readonly socialIds = Services.social.map((service) => service.id);
}

export function isSocialService(type: SocialService | string): type is SocialService {
  return Object.values(SocialService).includes(type as SocialService);
}

export const socialPrefixes: Record<SocialService, string> = {
  [SocialService.Facebook]: 'https://facebook.com/',
  [SocialService.Twitter]: 'https://twitter.com/',
  [SocialService.Whatsapp]: 'https://wa.me/',
  [SocialService.Linkedin]: 'https://linkedin.com/in/',
  [SocialService.Email]: 'mailto:',
};

export const socialPlaceholders: Record<SocialService, string> = {
  [SocialService.Facebook]: `${socialPrefixes[SocialService.Facebook]}xxx`,
  [SocialService.Twitter]: `${socialPrefixes[SocialService.Twitter]}xxx`,
  [SocialService.Whatsapp]: '+358400000000',
  [SocialService.Linkedin]: `${socialPrefixes[SocialService.Linkedin]}xxx`,
  [SocialService.Email]: 'example@zef.fi',
};
