import { Questions } from '@shared/enums/questions.enum';
import { QuestionData, SliderLabelsData, SliderValuesData } from '@shared/models/survey.model';
import { pickBy } from '@shared/utilities/object.utilities';

/**
 * Returns settings specific to the question type
 * with normalized and flattened values.
 *
 *   `{ sliderLabels: { min: '---', max: '' } } -> { 'sliderLabels/min': '---', 'sliderLabels/max': null }`
 *
 */
export function typeSettings(question: QuestionData) {
  /**
   * TODO: Verify these are required settings for each type
   */
  switch (question.type) {
    case Questions.SLIDER_1D:
    case Questions.SLIDER_1R:
    case Questions.SLIDER_NPS:
    case Questions.SLIDER_E_NPS:
      const props_1x = ['sliderAnswer', 'sliderSmileys', 'sliderLabels/', 'sliderValues/'];
      const flattened_1x = Questions.value(question, '/');
      return pickBy(flattened_1x, (value, key) => props_1x.some((label) => key.startsWith(label)));

    case Questions.SLIDER_2D:
      const props_2d = [
        'sliderAnswer',
        'sliderSmileys',
        'sliderLabelsX/',
        'sliderValuesX/',
        'sliderLabelsY/',
        'sliderValuesY/',
      ];
      const flattened_2d = Questions.value(question, '/');
      return pickBy(flattened_2d, (value, key) => props_2d.some((label) => key.startsWith(label)));

    case Questions.CHOICE_TEXT:
    case Questions.CHOICE_SINGLE:
    case Questions.CHOICE_PICTURE:
    case Questions.CHOICE_MULTI:
      const props_choice = ['showStatus', 'choiceLimit', 'image', 'choiceLayout', 'choiceZoom', 'choiceShuffle'];
      const flattened_choice = Questions.value(question, '/');
      return pickBy(flattened_choice, (value, key) => props_choice.some((label) => key.startsWith(label)));

    case Questions.FREE_TEXT:
      const props_free = ['mandatory', 'charsLimit', 'inputField/hint'];
      const flattened_free = Questions.value(question, '/');
      return pickBy(flattened_free, (value, key) => props_free.some((label) => key.startsWith(label)));

    case Questions.AI_INTERVIEWER:
      const props_interviewer = [
        'mandatory',
        'inputField/hint',
        'interviewer/rootQuestion',
        'interviewer/maxQuestions',
        'interviewer/publicReference',
        'interviewer/goal',
      ];
      const flattened_interviewer = Questions.value(question, '/');
      return pickBy(flattened_interviewer, (value, key) => props_interviewer.some((label) => key.startsWith(label)));

    case Questions.ESKO_WHY_FINDER:
      const props_whyFinder = ['mandatory', 'inputField/hint', 'whyFinder/target', 'whyFinder/maxQuestions'];
      const flattened_whyFinder = Questions.value(question, '/');
      return pickBy(flattened_whyFinder, (value, key) => props_whyFinder.some((label) => key.startsWith(label)));

    case Questions.INPUT_NUMERIC:
      const props_numeric = [
        'mandatory',
        'sliderValues/min',
        'sliderValues/max',
        'sliderValues/step',
        'inputField/hint',
      ];
      const flattened_numeric = Questions.value(question, '/');
      return pickBy(flattened_numeric, (value, key) => props_numeric.some((label) => key.startsWith(label)));

    case Questions.INPUT_DROPDOWN:
      const props_dropdown = ['mandatory', 'inputField/hint'];
      const flattened_dropdown = Questions.value(question, '/');
      return pickBy(flattened_dropdown, (value, key) => props_dropdown.some((label) => key.startsWith(label)));

    case Questions.INPUT_CHECKBOX:
      const props_checkbox = ['mandatory', 'inputField/'];
      const flattened_checkbox = Questions.value(question, '/');
      return pickBy(flattened_checkbox, (value, key) => props_checkbox.some((label) => key.startsWith(label)));

    case Questions.FILE_UPLOAD:
      const props_file_upload = ['fileUpload', 'mandatory'];
      const flattened_file_upload = Questions.value(question, '/');
      return pickBy(flattened_file_upload, (value, key) => props_file_upload.some((label) => key.startsWith(label)));

    // Input questions
    default:
      const props_default = ['mandatory', 'inputField/hint'];
      const flattened_default = Questions.value(question, '/');
      return pickBy(flattened_default, (value, key) => props_default.some((label) => key.startsWith(label)));
  }
}

export function sliderSettings(question: QuestionData) {
  question = { ...question };

  switch (question.type) {
    case Questions.GROUP_SCORED:
      const props_scored = [
        'sliderLabels/',
        'sliderValues/',
        'sliderLabelsX/',
        'sliderValuesX/',
        'sliderLabelsY/',
        'sliderValuesY/',
        'sliderSmileys',
      ];
      const flattened_scored = Questions.value(question, '/');
      return pickBy(
        flattened_scored,
        (value, key) => props_scored.some((label) => key.startsWith(label)) && value !== null,
      );

    case Questions.SLIDER_1D:
    case Questions.SLIDER_1R:
      question.sliderValues = {
        ...new SliderValuesData(),
        ...(question.sliderValues || {}),
      };

      question.sliderLabels = {
        ...new SliderLabelsData(),
        ...(question.sliderLabels || {}),
      };

      question.sliderSmileys = question.sliderSmileys || false;

      const props_1x = ['sliderLabels/', 'sliderValues/', 'sliderSmileys'];
      const flattened_1x = Questions.value(question, '/');
      return pickBy(flattened_1x, (value, key) => props_1x.some((label) => key.startsWith(label)) && value !== null);

    case Questions.SLIDER_2D:
      question.sliderValuesX = {
        ...new SliderValuesData(),
        ...(question.sliderValuesX || {}),
      };

      question.sliderValuesY = {
        ...new SliderValuesData(),
        ...(question.sliderValuesY || {}),
      };

      question.sliderLabelsX = {
        ...new SliderLabelsData(),
        ...(question.sliderLabelsX || {}),
      };

      question.sliderLabelsY = {
        ...new SliderLabelsData(),
        ...(question.sliderLabelsY || {}),
      };

      question.sliderSmileys = question.sliderSmileys || false;

      const props_2d = ['sliderLabelsX/', 'sliderValuesX/', 'sliderLabelsY/', 'sliderValuesY/', 'sliderSmileys'];
      const flattened_2d = Questions.value(question, '/');
      return pickBy(flattened_2d, (value, key) => props_2d.some((label) => key.startsWith(label)) && value !== null);
  }
}

/**
 * Compares settings specific to the question type
 */
export function settingsChanged(group: QuestionData, question: QuestionData) {
  const type = question.type;
  const hasScoredSettings = JSON.stringify(sliderSettings(question)) !== '{}';

  if ((type !== Questions.SLIDER_1D && type !== Questions.SLIDER_2D) || !hasScoredSettings) {
    return false;
  }

  const groupSettings = sliderSettings({ ...group, type });
  const questionSettings = sliderSettings(question);

  return JSON.stringify(groupSettings) !== JSON.stringify(questionSettings);
}

export const uniqueSettingsFilterFn = (v, i, a) => {
  // find first slider question and use it's type to filter settings
  const type = a.find((q) => q.type !== Questions.GROUP_SCORED).type;
  return (
    i ===
    a.findIndex(
      (q) =>
        JSON.stringify(sliderSettings({ ...q, type })) === JSON.stringify(sliderSettings({ ...v, type })) &&
        JSON.stringify(sliderSettings({ ...v, type })) !== '{}',
    )
  );
};
